<template>
    <div class="container">
        <section>
            <img alt="Linking Innovation" class="EvaluationForm-background" src="../assets/img/EvaluationForm/Banner.png">
        </section>
        <section class="section-product-text">{{ $t('EvaluationForm.section-product') }}</section>
        <section class="section-product">
            <div class="section-product-div">
                <div class="section-product-div-content" v-for="(item, index) in toolList" :Key="index">
                    <div v-if="index > 0">
                        <div class="section-product-div-title">{{ item.title }}</div>
                        <div class="section-product-div-desc">{{ item.desc }}</div>
                        <img :class="'section-product-div-img' + index" class="section-product-div-img" :src="item.url"
                            alt="Linking Innovation" />
                    </div>
                    <div v-else class="section-product-div-one-title">{{ item.title }}</div>
                </div>
            </div>
        </section>
        <section class="section-product-text-one">{{ $t('EvaluationForm.Get') }}</section>
        <section class="section-product">
            <div class="section-product-div section-product-div-phone">
                <img class="left-img" src="../assets/img/EvaluationForm/Knee.png" alt="" />
                <div class="rignt-content">
                    <div class="rignt-content-div" v-for="(item, index) in obtainlist" :key="index">
                        <span class="greenspliter"></span>
                        <div>
                            <div class="rignt-content-div-title">{{ item.title }}</div>
                            <div class="rignt-content-div-desc">{{ item.desc }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <a-button type="primary" class="FreeTrial" @click="openform">{{ $t('EvaluationForm.Free') }}
        </a-button>
        <a-modal :visible="modelvisible" :closable="true" @cancel="cancel" :footer="null" class="modal-free">
            <div class="EvaluationForm">
                <a-form-model class="transfer-form" ref="ruleForm" :model="form" :rules="rules">
                    <div class="free-title">免费试用</div>
                    <div class="free-desc">请填写以下信息，方便与您取得联系，已开启隐私保护</div>
                    <a-form-model-item prop="city" class="form-model-item">
                        <a-input :placeholder="'* ' + $t('EvaluationForm.EnterCity')" v-model="form.city"
                            class="input-class" />
                    </a-form-model-item>
                    <a-form-model-item prop="companyname" class="form-model-item">
                        <a-input :placeholder="'* ' + $t('EvaluationForm.EnterCompany')" v-model="form.companyname"
                            class="input-class" />
                    </a-form-model-item>
                    <a-form-model-item prop="email" class="form-model-item">
                        <a-input :placeholder="'* ' + $t('EvaluationForm.EnterMailBox')" v-model="form.email"
                            class="input-class" />
                    </a-form-model-item>
                    <a-form-model-item prop="telphone" class="form-model-item">
                        <a-input :placeholder="'* ' + $t('EvaluationForm.EnterPhone')" v-model="form.telphone"
                            class="input-class" />
                    </a-form-model-item>
                    <a-form-model-item prop="verificationCode" class="form-model-item">
                        <div class="code-class">
                            <a-input :placeholder="'* ' + $t('EvaluationForm.EnterCode')" v-model="form.verificationCode"
                                class="code-class-input" />
                            <span class="getCaptcha" @click="refreshCode">
                                <IdentifyCode :identify-code="identifyCode" :content-width="100" :content-height="30">
                                </IdentifyCode>
                            </span>
                        </div>
                    </a-form-model-item>
                    <a-button type="primary" @click="onSubmit" class="submit-class" :loading="loading">
                        {{ loading ? $t('EvaluationForm.Submiting') : $t('EvaluationForm.Submit') }}</a-button>
                </a-form-model>
            </div>
        </a-modal>
    </div>
</template>
  
<script>
import { postfreetrial } from '@/api/role'
import IdentifyCode from "vue-identifycode";
export default {
    name: 'EvaluationForm',
    components: { IdentifyCode },
    data() {
        return {
            identifyCode: '', //验证码
            toolList: [
                {
                    title: this.$t('EvaluationForm.desc'),
                },
                {
                    title: this.$t('EvaluationForm.Evaluation.EvaluationOne.title'),
                    desc: this.$t('EvaluationForm.Evaluation.EvaluationOne.data'),
                    url: require("../assets/img/EvaluationForm/ImageQualityControl.png"),
                },
                {
                    title: this.$t('EvaluationForm.Evaluation.EvaluationTwo.title'),
                    desc: this.$t('EvaluationForm.Evaluation.EvaluationTwo.data'),
                    url: require("../assets/img/EvaluationForm/IntelligentSegmentation.png"),
                },
                {
                    title: this.$t('EvaluationForm.Evaluation.EvaluationThree.title'),
                    desc: this.$t('EvaluationForm.Evaluation.EvaluationThree.data'),
                    url: require("../assets/img/EvaluationForm/DimensionalReconstruction.png"),
                },
                {
                    title: this.$t('EvaluationForm.Evaluation.EvaluationFour.title'),
                    desc: this.$t('EvaluationForm.Evaluation.EvaluationFour.data'),
                    url: require("../assets/img/EvaluationForm/MeasurementLabel.png"),
                },
                {
                    title: this.$t('EvaluationForm.Evaluation.EvaluationFive.title'),
                    desc: this.$t('EvaluationForm.Evaluation.EvaluationFive.data'),
                    url: require("../assets/img/EvaluationForm/PlanningSimulation.png"),
                }
            ],
            obtainlist: [
                {
                    title: this.$t('EvaluationForm.Obtain.ObtainOne.title'),
                    desc: this.$t('EvaluationForm.Obtain.ObtainOne.data'),
                },
                {
                    title: this.$t('EvaluationForm.Obtain.ObtainTwo.title'),
                    desc: this.$t('EvaluationForm.Obtain.ObtainTwo.data'),
                },
                {
                    title: this.$t('EvaluationForm.Obtain.ObtainThree.title'),
                    desc: this.$t('EvaluationForm.Obtain.ObtainThree.data'),
                },
                {
                    title: this.$t('EvaluationForm.Obtain.ObtainFour.title'),
                    desc: this.$t('EvaluationForm.Obtain.ObtainFour.data'),
                },
                {
                    title: this.$t('EvaluationForm.Obtain.ObtainFive.title'),
                    desc: this.$t('EvaluationForm.Obtain.ObtainFive.data'),
                }
            ],
            loading: false,
            modelvisible: false,
            form: {
                city: "",//城市
                companyname: "",//公司名称
                telphone: '', //填写者电话
                email: '',//邮箱
                verificationCode: '', //验证码
            },
            rules: {
                city: [{ required: true, message: this.$t('EvaluationForm.EnterCity'), trigger: 'change' }],
                companyname: [{ required: true, message: this.$t('EvaluationForm.EnterCompany'), trigger: 'change' }],
                email: [{
                    required: true,
                    pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                    message: this.$t('EvaluationForm.EnterMailBox'),
                    trigger: 'change'
                },
                {
                    validator: (rule, value, callback) => {
                        const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
                        if ((!reg.test(value)) && value != '') {
                            return callback(new Error())
                        } else {
                            callback()
                        }
                    }, message: this.$t('EvaluationForm.ErrorMailBox')
                }],
                telphone: [{
                    required: true,
                    message: this.$t('EvaluationForm.EnterPhone'),
                    trigger: 'change'
                },
                {
                    validator: (rule, value, callback) => {
                        const reg = /^1[34578]\d{9}$/
                        if ((!reg.test(value)) && value != '') {
                            return callback(new Error())
                        } else {
                            callback()
                        }
                    }, message: this.$t('EvaluationForm.ErrorPhone')
                }],
                verificationCode: [{ required: true, message: this.$t('FreeTrial.EnterCode'), trigger: 'change' },
                {
                    validator: (rule, value, callback) => {
                        if (this.identifyCode == value.toUpperCase()) {
                            callback()
                        } else {
                            return callback(new Error())
                        }
                    }, message: this.$t('FreeTrial.ErrorCode')
                }]
            }
        };
    },
    mounted() {
        this.identifyCode = this.createCode();
    },
    methods: {
        //生成随机数
        random(min, max) {
            return parseInt(Math.random() * (max - min + 1) + min);
        },
        //生成验证码
        createCode() {
            let code = "";
            const codeLength = 4; // 验证码的长度
            const random = [2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]; // 随机数
            for (let i = 0; i < codeLength; i++) {
                // 循环操作
                const index = Math.floor(Math.random() * 32); // 取得随机数的索引（0~35）
                code += random[index]; // 根据索引取得随机数加到code上
            }
            return code;
        },
        refreshCode() {
            this.identifyCode = this.createCode();
        },
        cancel() {
            this.modelvisible = false;
            this.form.city = ""//城市
            this.form.companyname = ""//公司名称
            this.form.telphone = '' //填写者电话
            this.form.email = ''//邮箱
            this.form.verificationCode = '' //验证码
        },
        openform() {
            this.refreshCode();
            this.modelvisible = true;
        },
        onSubmit() {
            let that = this;
            that.$refs.ruleForm.validate(valid => {
                if (valid) {
                    that.loading = true;
                    const formData = new FormData();
                    formData.append('city', that.form.city);
                    formData.append('companyname', that.form.companyname);
                    formData.append('email', that.form.email);
                    formData.append('telphone', that.form.telphone);
                    postfreetrial(formData).then(res => {
                        if (res.Result == 200) {
                            that.loading = false;
                            that.cancel();
                        } else {
                            that.loading = false;
                        }
                    }).catch((error) => {
                        that.loading = false;
                    })
                } else {
                    return false;
                }
            });
        },
    },
}
</script>
<style scoped lang="less">
.section-product-text {
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 105px;
    margin-bottom: 94px;
    width: 100%;
    padding: 0 200px;
}

.section-product-text-one {
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 100px;
    margin-bottom: 60px;
    width: 100%;
    padding: 0 200px;
}

.EvaluationForm-background {
    display: block;
    width: 100%;
    height: 600px;
}

.section-product {
    padding: 0 190px;
}

.section-product-div {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}

.section-product-div-content {
    position: relative;
    width: 33%;
    height: 338px;
    margin: 2px;
    text-align: start;
    background: linear-gradient(153deg, #333333 0%, #202020 100%);
}

.section-product-div-content:nth-child(1) {

    background: linear-gradient(136deg, #24E8A6 0%, #09CDD3 100%);
}

.section-product-div-one-title {
    text-align: start;
    white-space: pre-wrap;
    font-size: 50px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 100;
    color: #232323;
    letter-spacing: 3px;
    line-height: 70px;
    font-family: -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    margin: 100px 0 0 100px;
}

.section-product-div-title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #22DCA1;
    line-height: 50px;
    margin: 32px 40px 2px;
}

.section-product-div-desc {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.7;
    line-height: 33px;
    margin: 0 40px;
}

.section-product-div-img {
    display: block;
    position: absolute;
}

.section-product-div-img1 {
    width: 305px;
    height: 200px;
    right: 26px;
    bottom: 18px;
}

.section-product-div-img2 {
    width: 253px;
    height: 213px;
    right: 34px;
    bottom: 14px;
}

.section-product-div-img3 {
    width: 195px;
    height: 214px;
    right: 68px;
    bottom: 4px;
}

.section-product-div-img4 {
    width: 224px;
    height: 212px;
    right: 50px;
    bottom: 6px;
}

.section-product-div-img5 {
    width: 230px;
    height: 222px;
    right: 60px;
    bottom: 2px;
}

.left-img {
    display: block;
    width: 563px;
    height: 578px;
}

.rignt-content {
    flex: 1;
    background: #232323;
    padding: 28px 24px 28px 108px;
}

.rignt-content-div {
    display: flex;
    text-align: start;
}

.rignt-content-div:nth-child(2n) {
    margin: 38px 0;
}

.greenspliter {
    display: inline-block;
    width: 6px;
    height: 30px;
    background: #22DCA1;
    margin: 6px 16px 0 0;
}

.rignt-content-div-title {
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 42px;
}

.rignt-content-div-desc {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    line-height: 28px;
}

.FreeTrial {
    width: 300px;
    height: 56px;
    margin: 58px 0;
    color: #333333;
    font-size: 26px;
    background: linear-gradient(136deg, #24E8A6 0%, #07CBD5 100%);
    border-radius: 2px;
}

/deep/.ant-modal-body {
    box-sizing: border-box;
    padding: 40px 46px 36px;
    font-family: PingFangSC-Medium, PingFang SC;
}

/deep/.ant-modal-content {
    width: 552px;
    background: #212121;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.5);
}

.free-title {
    font-size: 34px;
    font-weight: 500;
    color: #1CE8A8;
    line-height: 48px;
    text-align: start;
}

.free-desc {
    font-size: 16px;
    font-weight: 400;
    color: #D6D6D6;
    line-height: 22px;
    margin: 2px auto 32px;
    text-align: start;
}

.submit-class {
    width: 430px;
    height: 40px;
    font-size: 20px;
    color: #333333;
    background: linear-gradient(136deg, #24E8A6 0%, #07CBD5 100%);
    border-radius: 2px;
    margin-top: 10px;
}

.code-div {
    display: flex;
}

.code-button {
    width: 163px;
    height: 38px;
    background: rgba(243, 243, 243, 0.25);
    border-radius: 2px;
    margin-left: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #D6D6D6;
    border: none;
}

/deep/.ant-input {
    height: 38px;
}

/deep/.ant-form-item {
    margin-bottom: 20px;
}

/deep/.has-error .ant-form-explain {
    text-align: start;
}

/deep/.ant-modal-close-x {

    color: #606060;

    .anticon svg {
        width: 24px;
        height: 24px;
    }
}

.code-class {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.getCaptcha {
    display: inline-block;
    cursor: pointer;
    margin-left: 24px;
}

/deep/ #s-canvas {
    width: 126px;
    height: 38px;
}

/deep/ .s-canvas {
    width: 126px;
    height: 38px;
}

@media screen and (max-width: 768px) {
    .EvaluationForm-background {
        display: block;
        width: 100%;
        height: 140px;
    }

    .section-product-text {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
        margin: 10px 0;
        width: 100%;
        padding: 0 12px;
    }

    .section-product-text-one {
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
        margin-top: 20px;
        margin-bottom: 10px;
        width: 100%;
        padding: 0 2px;
    }


    .section-product {
        padding: 0 4px;
    }

    .section-product-div {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
    }

    .section-product-div-content {
        position: relative;
        width: 32%;
        height: 96px;
        margin: 1px;
        text-align: start;
        background: linear-gradient(153deg, #333333 0%, #202020 100%);
    }

    .section-product-div-content:nth-child(1) {

        background: linear-gradient(136deg, #24E8A6 0%, #09CDD3 100%);
    }

    .section-product-div-one-title {
        text-align: start;
        white-space: pre-wrap;
        font-size: 12px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 100;
        color: #232323;
        letter-spacing: 1px;
        line-height: 12px;
        font-family: -apple-system, BlinkMacSystemFont,
            "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
            "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif;
        margin: 30px 0 0 6px;
    }

    .section-product-div-title {
        font-size: 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #22DCA1;
        line-height: 10px;
        margin: 8px 6px 4px;
    }

    .section-product-div-desc {
        font-size: 6px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.7;
        line-height: 10px;
        margin: 0 6px;
    }

    .section-product-div-img {
        display: block;
        position: absolute;
        width: 60px;
        height: 40px;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 6px;
    }

    .section-product-div-phone {
        flex-direction: column;
    }

    .left-img {
        display: block;
        width: 90%;
        height: 100px;
        margin: 0 auto 2px;
    }

    .rignt-content {
        flex: 1;
        background: #232323;
        padding: 6px 4px 6px 2px;
    }

    .rignt-content-div {
        display: flex;
        text-align: start;
    }

    .rignt-content-div:nth-child(2n) {
        margin: 12px 0;
    }

    .greenspliter {
        display: inline-block;
        width: 2px;
        height: 14px;
        background: #22DCA1;
        margin: 2px 6px 0 0;
    }

    .rignt-content-div-title {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 12px;
    }

    .rignt-content-div-desc {
        font-size: 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.7);
        line-height: 12px;
    }

    .FreeTrial {
        width: 100px;
        height: 28px;
        margin: 12px 0;
        color: #333333;
        font-size: 10px;
        background: linear-gradient(136deg, #24E8A6 0%, #07CBD5 100%);
        border-radius: 2px;
    }

    /deep/.ant-modal-body {
        box-sizing: border-box;
        padding: 4px;
        font-family: PingFangSC-Medium, PingFang SC;
    }

    /deep/.ant-modal-content {
        width: 100%;
        background: #212121;
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.5);
    }

    .free-title {
        font-size: 14px;
        font-weight: 500;
        color: #1CE8A8;
        line-height: 14px;
        text-align: start;
    }

    .free-desc {
        font-size: 12px;
        font-weight: 400;
        color: #D6D6D6;
        line-height: 12px;
        margin: 2px auto 6px;
        text-align: start;
    }

    .submit-class {
        width: 100px;
        height: 28px;
        font-size: 10px;
        color: #333333;
        background: linear-gradient(136deg, #24E8A6 0%, #07CBD5 100%);
        border-radius: 2px;
        margin-top: 4px;
    }

    .code-div {
        display: flex;
    }

    .code-button {
        width: 80px;
        height: 28px;
        background: rgba(243, 243, 243, 0.25);
        border-radius: 2px;
        margin-left: 20px;
        font-weight: 400;
        font-size: 10px;
        color: #D6D6D6;
        border: none;
    }

    /deep/.ant-input {
        height: 28px;
        font-size: 10px;
    }

    /deep/.ant-form-item {
        margin-bottom: 6px;
    }

    /deep/.has-error .ant-form-explain {
        min-height: 12px;
        font-size: 8px;
        text-align: start;
    }

    /deep/.ant-form-item-control {
        line-height: 1;
    }

    /deep/.ant-modal-close-x {
        font-size: 8px;
        margin-top: 2px;
        margin-right: 2px;
        width: 8px;
        height: 8px;
        line-height: 1;
        color: #606060;
        top: 2px;

        .anticon svg {
            width: 8px;
            height: 8px;
        }
    }

    .code-class {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .getCaptcha {
        display: inline-block;
        cursor: pointer;
        margin-left: 12px;
    }

    /deep/ #s-canvas {
        width: 80px;
        height: 28px;
    }

    /deep/ .s-canvas {
        width: 80px;
        height: 28px;
    }

}
</style>